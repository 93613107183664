import React, { useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Banner from '../Banner'

import Container from 'components/Container';
import Img1 from '../../../../assets/images/home_hero_2.jpeg'

const Hero = () => {
  useEffect(() => {
    const jarallaxInit = async () => {
      const jarallaxElems = document.querySelectorAll('.jarallax');
      if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
        return;
      }

      const { jarallax } = await import('jarallax');
      jarallax(jarallaxElems, { speed: 0.2 });
    };

    jarallaxInit();
  });

  return (
    <Box
      className={'jarallax'}
      data-jarallax
      data-speed="0.2"
      position={'relative'}
      minHeight={{ xs: 400, sm: 500, md: 600 }}
      display={'flex'}
      alignItems={'center'}
      marginTop={-13}
      paddingTop={13}
      id="agency__portfolio-item--js-scroll"
    >
      <Box
        className={'jarallax-img'}
        image={Img1}
        sx={{
          position: 'absolute',
          objectFit: 'cover',
          /* support for plugin https://github.com/bfred-it/object-fit-images */
          fontFamily: 'object-fit: cover;',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundImage: `url(${Img1})`,

          // image
          // src: Img1,
          // backgroundImage: 
          // 'url(https://assets.maccarianagency.com/backgrounds/img52.jpg)',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1,
          background: alpha('#161c2d', 0.4),
          zIndex: 1,
        }}
      />
      <Container position={'relative'} zIndex={2}>
        <Box>
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              fontWeight: 500,
              color: 'common.white',
              textTransform: 'uppercase',
              fontFamily: "Poppins"
            }}
          >
            FOSMO
          </Typography>
          <Typography
            variant="h6"
            component="p"
            color="text.primary"
            sx={{
              color: 'common.white',
              fontFamily: "Poppins",
              fontWeight: 300
            }}
          >
            FOSMO WAYANAD (Forum For Old Students of Muslim Orphanage, Wayanad) is an organization formed by the alumni of Wayanad Muslim Orphanage (WMO), Kerala, India. It's platform of the former inmates of the WMO to connect and network between themselves, to ensure their welfare and to contribute to the overall development of WMO.
          </Typography>
        </Box>
        <Box sx={{ marginTop: 5 }}>
          <Banner />
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;