import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Main from 'layouts/Main';
import Container from 'components/Container';
import {
  Contact,
  Gallery,
  Hero,
  Partners,
  Story,
  Team,
  WhoWeAre,
  SpecialInvitees,
  Advisory,
  Register,
  Exicutive,
  Guardians
} from './components';


const About = () => {

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main colorInvert={true}>
      <Hero />
      <Container>
        <Story />
      </Container>
      <Container paddingTop={'0 !important'}>
        <WhoWeAre />
      </Container>
      <Container maxWidth={800} paddingY={'0 !important'}>
        <Divider />
      </Container>
      <Box sx={{ backgroundColor: "#d1c4e9" }}>
        <Container>
          <Guardians />
        </Container>
      </Box>
      <Container maxWidth={800} paddingY={'0 !important'}>
        <Divider />
      </Container>
      <Box>
        <Container>
          <Advisory />
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "#d1c4e9" }}>
        <Container>
          <Team />
        </Container>
      </Box>
      <Container>
        <Exicutive />
      </Container>
      <Container>
        <SpecialInvitees />
      </Container>
      <Box sx={{ backgroundColor: "#9d4edd" }}>
        <Container>
          <Register />
        </Container>
      </Box>
    </Main>
  );
};

export default About;
