import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getEvents } from 'features/events/eventSlice'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { minWidth } from '@mui/system';


const News = () => {

  const events = useSelector((state) => state.event.data)
  const loading = useSelector((state) => state.event.isLoading)

  // const dispatch = useDispatch()

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  React.useEffect(() => {
    // if (!events) {
    //   console.log("fetching")
    //   dispatch(getEvents())
    // }
    console.log("loading", events)
  }, [events])

  // console.log(events, events?.length)
  // const dataToShow = isMd ? events : events?.slice(0, events?.length - 1);



  // if (loading) {
  //   return <Typography variant="subtitle1" color="text.secondary">
  //     Loading....
  //   </Typography>
  // }

  // if (!events) {
  //   return <Typography variant="subtitle1" color="text.secondary">
  //     No events
  //   </Typography>
  // }

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant={'h4'}
          gutterBottom
          align={'center'}
          sx={{ fontWeight: 600, fontFamily: "Poppins" }}

        >
          {"NEWS & EVENTS"}
        </Typography>
        <Typography
          variant={'h6'}
          component={'p'}
          color={'text.secondary'}
          align={'center'}
          sx={{ fontFamily: "Poppins", fontWeight: 200 }}
        >
          Stay upto date with what we do
        </Typography>
      </Box>
      <Grid container spacing={isMd ? 4 : 2} >
        <Grid item xs={12} md={6} >
          <Grid container spacing={isMd ? 4 : 2} >
            {events?.map((event, index) => (
              <Grid
                item
                xs={12}
                md={6}
                key={index}
                paddingRight={isMd ? 0 : 3}
                // data-aos="fade-up"
                data-aos-delay={index * 200}
                data-aos-offset={100}
                data-aos-duration={600}
              >
                <Box
                  component={Card}
                  display={'flex'}
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  // width={100}
                  backgroundColor="#ede7f6"

                >
                  <CardMedia
                    title={event.title}
                    image={event?.photo?.photo}
                    sx={{
                      fontFamily: "Poppins",
                      height: { xs: 240, sm: 'auto' },
                      width: { xs: 1, sx: 200 },
                      minWidth: { md: 200 },
                      maxWidth: { md: 200 },
                    }}
                  />
                  <CardContent>
                    <Box>
                      <Typography
                        variant="h6"
                        gutterBottom
                        color="text.primary"
                        sx={{ fontFamily: "Poppins", fontWeight: 400 }}
                      >
                        {event.title}
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary" sx={{ fontFamily: "Poppins", fontWeight: 300 }}>
                        {event?.description.substring(0, 2000)}
                      </Typography>
                    </Box>
                    <CardActions sx={{ justifyContent: 'flex-end' }}>
                      <Button>Read More</Button>
                    </CardActions>
                  </CardContent>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      {/* read more */}
      <Box display={'flex'} justifyContent={"center"} alignSelf={'center'}  >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
          marginTop={{ xs: 5, md: 8 }}

        >
          <Box display="flex" marginTop={{ xs: 2, md: 0 }}>
            <Button variant="outlined" color="primary" size="large">
              Load more ...
            </Button>
          </Box>
        </Box>
      </Box>
      {/* read more  */}
    </Box>
  );
};

export default News;
