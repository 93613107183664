import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';

import Main from 'layouts/Main';
import Container from 'components/Container';
import {
  Gallery,
  Hero,
  Banner,
  Team,
  Story,
  News,
  WhoWeAre,
  Application
} from './components';
import useMediaQuery from '@mui/material/useMediaQuery';


const About = () => {
  const theme = useTheme();
  return (
    <>
      <Main colorInvert={true}>
        <Hero />
        <Container>
          <Story />
        </Container>
        <Container paddingTop={'0 !important'}>
          <WhoWeAre />
        </Container>
        <Container maxWidth={800} paddingY={'0 !important'}>
          <Divider />
        </Container>
        <Box sx={{ backgroundColor: "#b39ddb" }} bgcolor={'alternate.main'}>
          <Container>
            <News />
          </Container>
        </Box>
        <Container>
          <Gallery />
        </Container>
        <Box bgcolor={'#d1c4e9'}>
          <Container>
            <Team />
          </Container>
        </Box>
        <Container>
          <Application />
        </Container>
      </Main>
    </>
  );
};

export default About;
